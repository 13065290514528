@import url('https://fonts.googleapis.com/css2?family=Inter&family=League+Gothic&display=swap');

html {
  scroll-behavior: smooth;
  font-size: 62.5%
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: transparent;
  font-family: 'Inter', sans-serif;
  color: white;
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
}
