@media screen and (min-width: 2000px) {
  html {
    font-size: 80%;
  }

  .app__content {
    max-height: 60vh !important;
    max-width: 75vw !important;
  }
}


@media screen and (max-width: 2300px) {

  .work__header {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: flex-start !important;
  }
}


@media screen and (max-width: 2000px) {
  .app {
    overflow-y: scroll !important;
  }

  .app__content {
    max-height: fit-content !important;
    height: fit-content !important;
    grid-template-columns: repeat(6, auto) !important;
    grid-template-rows: repeat(6, auto) !important;
    grid-template-areas: 
      "section1 section7 section7 section7 section5 section5"
      "section2 section2 section2 section2 section5 section5"
      "section4 section4 section3 section3 section5 section5"
      "section4 section4 section3 section3 section5 section5"
      "section4 section4 section6 section6 section5 section5"
      "section4 section4 section6 section6 section5 section5";
    gap: 2rem;
  }
}

@media screen and (max-width: 1921px) {
  html {
    font-size: 65%;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 45%;
  }

  .App {
    min-height: 100vh !important;
    overflow: scroll !important;
    align-items: flex-start !important;
  }
  
  .app__content {
    padding: 2rem !important;
    width: 95vw;

    max-width: 95vw;
    grid-template-columns: repeat(4, auto) !important;
    grid-template-rows: repeat(4, auto) !important;
    grid-template-areas: 
      "section1 section1 section7 section7"
      "section2 section2 section6 section6"
      "section3 section3 section3 section3"
      "section4 section4 section5 section5" !important;
    gap: 1.5rem !important;
  }

  .blobs {
    display: none;
  }

  .hero {
    background: radial-gradient(circle at center, rgba(167, 86, 19, 1), rgba(167, 86, 19, 0) 90%) no-repeat;
  }

  .certs__container {
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    gap: 2rem !important;
  }

  .certs__item {
    height: auto !important;
  }

  .certs__divider {
    display: none;
  }

  #mouse {
    display: none;
  }

  .frame {
    opacity: 1 !important;
    filter: blur(0) !important;
  }

  .work {
    display: block !important;
  }

  .frame__title {
    margin-bottom: 1rem;
  }


  .status__progress {
    height: 1rem !important;
  }
}

@media screen and (max-width: 600px) {
  .app__content {
    width: 95vw;
    max-width: 95vw;
    grid-template-columns: auto auto !important;
    grid-template-rows: repeat(7, auto) !important;
    grid-template-areas: 
      "section1 section1"
      "section3 section3"
      "section5 section5"
      "section2 section2"
      "section4 section4"
      "section6 section6"
      "section7 section7" !important;
  }

  .frame {
    max-width: 95vw !important;
  }

}

@media screen and (max-width: 425px) {
  h1 {
    font-size: 5rem !important;
  }

  .projects {
    height: auto;
  }

  .item__desc {
    font-size: 1.5rem;
  }
}

@media screen and (max-height: 800px) {

}

@media screen and (max-height: 550px) {

}